import React from 'react';
import './LabelTemplate.css';
import { Row, Col } from 'antd';

interface LabelTemplateProps {
    item: any; // You can replace 'any' with a more specific type if available
    qrCodeDataURL?: string | null;
}

const LabelTemplate: React.FC<LabelTemplateProps> = React.memo(({ item, qrCodeDataURL }) => {
    const renderInfoRow = (label: string, value: string | number, width: number = 10) => (
        <div className="info-row">
            <Col span={width} className="info-label">{label}</Col>
            <Col span={24 - width} className="info-value">: {value}</Col>
        </div>
    );

    return (
        <div className="container">
            <div className="styled-div">
                <Row gutter={16}>
                    <Col span={4}>
                        {qrCodeDataURL && (
                            <img src={qrCodeDataURL} alt="QR Code" className="qr-code" />
                        )}
                    </Col>
                    <Col span={16} className="center-text">
                        <div className="title">BENIH UNGGUL BERSERTIFIKAT</div>
                        <div className="company-name">PT. RESTU AGROPRO JAYAMAS</div>
                        <div className="license-number">Nomor LSSM : LSSM-022-B</div>
                    </Col>
                    <Col span={4} className="right-text">
                        <img src="/logo.png" alt="Logo" className="logo" />
                    </Col>
                </Row>
                <Row gutter={16} className="info-section">
                    <Col span={14}>
                        {renderInfoRow('Nomor Induk', item.noInduk, 8)}
                        {renderInfoRow('Nomor Seri Label', item.noSeriAwal, 8)}
                        {renderInfoRow('No. Lot', item.noLot, 8)}
                        {renderInfoRow('Varietas', item.varietas, 8)}
                        {renderInfoRow('Jenis Tanaman', item.jenisTanaman, 8)}
                        {renderInfoRow('Kelas Benih', item.kelasBenih, 8)}
                        {renderInfoRow('Daya Berkecambah', `${item.dayaBerkecambah * 100 || 'N/A'}%`, 8)}
                        {renderInfoRow('Benih Murni', `${item.benihMurni * 100 || 'N/A'}%`, 8)}
                    </Col>
                    <Col span={10}>
                        {renderInfoRow('Tgl Selesai uji', new Date(item.tanggalSelesaiUji).toLocaleDateString(), 12)}
                        {renderInfoRow('Tgl Akhir Masa Edar', new Date(item.tanggalAkhirMasaEdar).toLocaleDateString(), 12)}
                        {renderInfoRow('CVL', item.cvlLapang || 'N/A', 12)}
                        {renderInfoRow('Benih Tan. Lain', `${item.gulma * 100 || 'N/A'}%`, 12)}
                        {renderInfoRow('Kotoran Benih', `${item.kotoranBenih * 100 || 'N/A'}%`, 12)}
                        {renderInfoRow('Kadar air', `${item.kadarAir * 100 || 'N/A'}%`, 12)}
                        {renderInfoRow('Isi kemasan', item.beratKemasan, 12)}
                    </Col>
                </Row>
            </div>
        </div>
    );
});

export default LabelTemplate; 