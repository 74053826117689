import React, { useState, useRef } from 'react';
import { Button, Form, Input, message } from 'antd';
import HttpService from '../../services/HttpService';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom';
import LabelTemplate from './LabelTemplate';

interface ExportLabelsModalContentProps {
    onCancel: () => void;
}

const ExportLabelsModalContent: React.FC<ExportLabelsModalContentProps> = ({ onCancel }) => {
    const [startNoBerkas, setStartNoBerkas] = useState('');
    const [endNoBerkas, setEndNoBerkas] = useState('');
    const httpService = new HttpService();
    const contentRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(false);

    const generatePDF = async (data: any[]) => {
        const pdf = new jsPDF('landscape', 'in', [9.5, 4.5]);
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            if (contentRef.current) {
                ReactDOM.render(<LabelTemplate item={item} />, contentRef.current);

                const canvas = await html2canvas(contentRef.current, { scale: 1 });
                const imgData = canvas.toDataURL('image/jpeg');

                const imgWidth = 9.5;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

                if (i < data.length - 1) {
                    pdf.addPage();
                }
            }
        }
        const filename = `Labels_${startNoBerkas}-${endNoBerkas}.pdf`;
        pdf.save(filename);
    };

    const handleExport = async () => {
        if (loading) return;

        setLoading(true);
        try {
            const data = await httpService.exportSertifikasiRange(startNoBerkas, endNoBerkas);
            if (data.data.length === 0) {
                message.warning('No data found for the specified range.');
            } else {
                await generatePDF(data.data);
                message.success('Labels exported successfully!');
            }
        } catch (error) {
            message.error('Failed to export labels');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form layout="vertical">
            <p>Select the range of No Berkas you want to export:</p>
            <Form.Item label="Start No Berkas">
                <Input 
                    type="number" 
                    value={startNoBerkas} 
                    onChange={(e) => setStartNoBerkas(e.target.value)} 
                    placeholder="0001" 
                />
            </Form.Item>
            <Form.Item label="End No Berkas">
                <Input 
                    type="number" 
                    value={endNoBerkas} 
                    onChange={(e) => setEndNoBerkas(e.target.value)} 
                    placeholder="0100" 
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" onClick={handleExport} disabled={loading}>
                    {loading ? 'Exporting...' : 'Export Labels'}
                </Button>
                <Button style={{ marginLeft: 8 }} onClick={onCancel}>Cancel</Button>
            </Form.Item>
            <div ref={contentRef} style={{ position: 'absolute', left: '-9999px', top: '-9999px' }} />
        </Form>
    );
};

export default ExportLabelsModalContent; 