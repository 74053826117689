import axios from "axios";
import { mapToUserModel } from "../components/users/user.util";
import { User } from "../components/users/user.type";
import { apiBaseUrl } from "../common/constants"; // Import the constant

const apiService = axios.create({
  baseURL: apiBaseUrl, // Use the imported constant
});

class HttpService {
  async getSertifikasiByCode(code: string): Promise<any> {
    const response = await apiService.get(
      `/sertifikasi/find-by-code?code=${code}`
    );
    return response.data;
  }

  async getListSertifikasi(): Promise<any> {
    const response = await apiService.get("/sertifikasi");
    return response.data;
  }

  async createSertifikasi(payload: any): Promise<any> {
    const response = await apiService.post("/sertifikasi", payload);
    return response.data;
  }

  async login(payload: any): Promise<any> {
    const response = await apiService.post("/login", payload);
    return response.data;
  }

  async deleteSertifikasi(id: string): Promise<any> {
    const response = await apiService.delete(`/sertifikasi/${id}`);
    return response.data;
  }

  async updateSertifikasi(id: string, payload: any): Promise<any> {
    const response = await apiService.patch(`/sertifikasi/${id}`, payload);
    return response.data;
  }

  async upload(file: any): Promise<any> {
    var formData = new FormData();
    formData.append("file", file);
    const response = await apiService.post(
      "/sertifikasi/bulk-insert",
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    return response.data;
  }

  async createUser(payload: any): Promise<any> {
    const response = await apiService.post("/register", payload);
    return response.data;
  }

  async deleteUser(id: string): Promise<any> {
    const response = await apiService.delete(`/users/${id}`);
    return response.data;
  }

  async getListUser(): Promise<User[]> {
    const response = await apiService.get("/users");
    console.log(response);
    const result = response.data.data.map((user: any) => {
      return mapToUserModel(user);
    });
    return result;
  }

  async exportSertifikasiRange(start: string, end: string): Promise<any> {
    const response = await apiService.get(`/sertifikasi/range?start=${start}&end=${end}`);
    return response.data;
  }
}

export default HttpService;
