import React, { useState } from 'react';
import { DeleteOutlined, DownOutlined, EditOutlined, ExportOutlined, EyeOutlined, FilePdfOutlined, PlusCircleOutlined, QrcodeOutlined } from '@ant-design/icons/lib/icons';
import { Button, Col, Dropdown, Input, Menu, message, Modal, Row, Table, TableColumnsType, TableProps } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Link } from 'react-router-dom';
import ExportQr from './ExportQr';
import Navbar from '../header/Navbar';
import { useHomeHooks } from './home.hooks';
import ExportLabelsModalContent from './ExportLabelsModalContent';
import { Sertifikasi } from './home.type';
import { SertifikasiModel } from '../../sertifikasi.type';
import ExportLabelByModalContent from './ExportLabelByRecord';

const Home = () => {
    const {
        listSertifikasi,
        filteredSertifikasi,
        searchQuery,
        deleteDialogVisible,
        isLoggedIn,
        isReadOnly,
        handleInputChange,
        generateAndDownloadQRCode,
        handleDelete,
        handleConfirmDelete,
        handleOpenView,
        handleCancelDelete,
    } = useHomeHooks();

    const [exportLabelsModalVisible, setExportLabelsModalVisible] = useState(false);
    const [exportByRecordModalVisible, setExportByRecordModalVisible] = useState(false);
    const [selectedSertifikasi, setSelectedSertifikasi] = useState<SertifikasiModel | undefined | null>(null);

    const handleOpenExportByRecordModal = (sertifikasi: SertifikasiModel) => {
        setExportByRecordModalVisible(true);
        setSelectedSertifikasi(sertifikasi);
    };

    const columns: TableColumnsType<Sertifikasi> = [
        {
            title: 'No Berkas',
            dataIndex: 'noBerkas',
            showSorterTooltip: { target: 'full-header' },
            onFilter: (value: any, record: Sertifikasi) => record.noBerkas.indexOf(value) === 0,
            sorter: (a, b) => a.noBerkas.localeCompare(b.noBerkas),
            sortDirections: ['descend'],
        },
        {
            title: 'No LOT',
            dataIndex: 'noLot',
            defaultSortOrder: 'descend',
            onFilter: (value: any, record) => record.noLot.indexOf(value) === 0,
            sorter: (a, b) => a.noLot.localeCompare(b.noLot),
        },
        {
            title: 'Produsen',
            dataIndex: 'produsen',
            sorter: (a, b) => a.produsen.localeCompare(b.produsen),
        },
        {
            title: 'Varietas',
            dataIndex: 'varietas',
            sorter: (a, b) => a.varietas.localeCompare(b.varietas),
        },
        {
            title: 'Kelas Benih',
            dataIndex: 'kelasBenih',
            onFilter: (value: any, record) => record.kelasBenih.indexOf(value) === 0,
            sorter: (a, b) => a.kelasBenih.localeCompare(b.kelasBenih),
        },
        {
            title: 'Tanggal Selesai Uji',
            dataIndex: 'tanggalSelesaiUji',
            width: '15%',
            onFilter: (value: any, record) => record.tanggalSelesaiUji.indexOf(value) === 0,
            sorter: (a, b) => a.tanggalSelesaiUji.localeCompare(b.tanggalSelesaiUji),
        },
        {
            title: 'Tanggal Akhir Masa Edar',
            dataIndex: 'tanggalAkhirMasaEdar',
            width: '15%',
            onFilter: (value: any, record) => record.tanggalAkhirMasaEdar.indexOf(value) === 0,
            sorter: (a, b) => a.tanggalAkhirMasaEdar.localeCompare(b.tanggalAkhirMasaEdar),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Dropdown overlay={(
                    <Menu>
                        {!isReadOnly && (
                            <Menu.Item key="edit">
                                <Link to={`/edit/${record.code}`}>
                                    <Button type="link" icon={<EditOutlined />}>Edit</Button>
                                </Link>
                            </Menu.Item>
                        )}
                        {!isReadOnly && (
                            <Menu.Item key="delete">
                                <Button type="link" icon={<DeleteOutlined />} onClick={() => handleDelete(record.id)}>Delete</Button>
                            </Menu.Item>
                        )}
                        <Menu.Item key="detail">
                            <Button onClick={() => handleOpenView(record.code)} type="link" icon={<EyeOutlined />}>View</Button>
                        </Menu.Item>
                        <Menu.Item key="qr">
                            <Button onClick={() => generateAndDownloadQRCode(record.code)} icon={<QrcodeOutlined />}>Download Qr Code</Button>
                        </Menu.Item>
                        <Menu.Item key="newExportLabels">
                            <Button onClick={() => handleOpenExportByRecordModal(record as Sertifikasi)} icon={<FilePdfOutlined />}>Export Labels</Button>
                        </Menu.Item>
                    </Menu>
                )} trigger={['click']}>
                    <Button type="link">
                        Actions <DownOutlined />
                    </Button>
                </Dropdown>
            ),
        },
    ];

    const onChange: TableProps<Sertifikasi>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    if (!isLoggedIn) {
        return (<div></div>);
    } else {
        return (
            <div>
                <Navbar />
                <Content className="site-layout" style={{ marginTop: 64 }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: '650px' }}>
                        <Row justify="end" style={{ marginBottom: '20px' }}>
                            <Col>
                                {!isReadOnly && (
                                    <Link to='/form'><Button type="primary" icon={<PlusCircleOutlined />} style={{ background: '#059749', borderColor: '#059749' }}>Create</Button></Link>
                                )}
                            </Col>
                            <Col style={{ marginLeft: 10 }}>
                                <ExportQr listSertifikasi={listSertifikasi} />
                            </Col>
                            {/* <Col style={{ marginLeft: 10 }}>
                                <Button type="primary" icon={<FilePdfOutlined />} style={{ background: '#059749', borderColor: '#059749' }} onClick={() => setExportLabelsModalVisible(true)}>Export Labels</Button>
                            </Col> */}
                        </Row>

                        <Row justify="end" style={{ marginBottom: '20px' }}>
                            <Col md={8} sm={12} xs={15}>
                                <Input placeholder='Search' value={searchQuery} onChange={handleInputChange} />
                            </Col>
                        </Row>

                        <div style={{ overflowX: 'auto' }}>
                            <Table
                                columns={columns}
                                dataSource={filteredSertifikasi}
                                onChange={onChange}
                                showSorterTooltip={{ target: 'sorter-icon' }}
                            />
                        </div>

                        <Modal
                            title="Confirm Delete"
                            visible={deleteDialogVisible}
                            onOk={handleConfirmDelete}
                            onCancel={handleCancelDelete}
                        >
                            <p>Are you sure you want to delete this sertifikasi?</p>
                        </Modal>

                        <Modal
                            title="Export Labels"
                            visible={exportLabelsModalVisible}
                            footer={null}
                            onCancel={() => setExportLabelsModalVisible(false)}
                        >
                            <ExportLabelsModalContent
                                onCancel={() => setExportLabelsModalVisible(false)}
                            />
                        </Modal>

                        <Modal
                            title="Export Labels"
                            visible={exportByRecordModalVisible}
                            footer={null}
                            onCancel={() => setExportByRecordModalVisible(false)}
                        >
                            <ExportLabelByModalContent 
                                sertifikasi={selectedSertifikasi as SertifikasiModel} 
                                onCancel={() => setExportByRecordModalVisible(false)}
                            />
                        </Modal>
                    </div>
                </Content>
            </div>
        );
    }
};

export default Home;
