import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HttpService from '../../services/HttpService';
import { mapListSertifikasi } from './home.util';
import { Sertifikasi } from './home.type';
import { message } from 'antd';
import QRCode from 'qrcode';

export const useHomeHooks = () => {
    const httpService = new HttpService();
    const [listSertifikasi, setListSertifikasi] = useState<Sertifikasi[]>([]);
    const [filteredSertifikasi, setFilteredSertifikasi] = useState<Sertifikasi[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [deleteRecordId, setDeleteRecordId] = useState('');
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isReadOnly, setIsReadonly] = useState(false);

    const getListSertifikasi = async () => {
        try {
            const response = await httpService.getListSertifikasi();
            const mappedSertifikasi = mapListSertifikasi(response.data);
            if (response) {
                setListSertifikasi(mappedSertifikasi);
                setFilteredSertifikasi(mappedSertifikasi);
            }
        } catch (error) {
            // Handle error
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setSearchQuery(query);
        search(query, ['noBerkas', 'noLot', 'produsen', 'varietas', 'kelasBenih', 'tanggalSelesaiUji', 'tanggalAkhirMasaEdar']);
    };

    const generateAndDownloadQRCode = async (code: string) => {
        const qrCodeDataURL = await QRCode.toDataURL(`https://q.benihraja.com/${code}`);
        const link = document.createElement('a');
        link.href = qrCodeDataURL;
        link.download = `${code}.png`;
        link.click();
    };

    const search = async (query: string, fields: (keyof Sertifikasi)[]) => {
        const filterResult = listSertifikasi.filter(item => {
            return fields.some(field => {
                const fieldValue = item[field];
                const stringValue = fieldValue as string;
                if (stringValue && typeof stringValue === 'string') {
                    return stringValue.toLowerCase().includes(query.toLowerCase());
                }
                return false;
            });
        });
        setFilteredSertifikasi(filterResult);
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        } else {
            setIsLoggedIn(true);
            getListSertifikasi();
        }
        const readonlyValue = localStorage.getItem('isReadOnly');
        if (readonlyValue) {
            try {
                const parsedValue = JSON.parse(readonlyValue);
                setIsReadonly(parsedValue === true);
            } catch (e) {
                console.error('Failed to parse isReadonly from localStorage', e);
            }
        }
    }, []);

    const handleDelete = (id: string) => {
        setDeleteRecordId(id);
        setDeleteDialogVisible(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await httpService.deleteSertifikasi(deleteRecordId);
            setDeleteDialogVisible(false);
            message.success('Sertifikasi deleted successfully');
            getListSertifikasi();
        } catch (error) {
            message.error('Failed to delete sertifikasi');
        }
    };

    const handleOpenView = (code: string) => {
        const url = `https://q.benihraja.com/${code}`;
        window.open(url, '_blank');
    };

    const handleCancelDelete = () => {
        setDeleteRecordId('');
        setDeleteDialogVisible(false);
    };

    return {
        listSertifikasi,
        filteredSertifikasi,
        searchQuery,
        deleteDialogVisible,
        isLoggedIn,
        isReadOnly,
        handleInputChange,
        generateAndDownloadQRCode,
        handleDelete,
        handleConfirmDelete,
        handleOpenView,
        handleCancelDelete,
    };
}; 